import { DATE_TIME_FORMAT } from "core/presentation/dateformats";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, {
  DataTableCell,
} from "template/presentation/components/table/DataTable";
import { Instance } from "../../domain/entities/Instance";

export interface InstancesTableProps {
  instances: Instance[] | null;
  isLoading: boolean;
  onReload: () => void;
  onAdd: () => void;
}

export default function InstancesTable(props: InstancesTableProps) {
  const { instances, isLoading, onReload, onAdd } = props;

  return (
    <DataContainer
      loading={isLoading}
      addButton="Neue Instanz"
      onAdd={onAdd}
      onReload={onReload}
    >
      <DataTable<Instance>
        loading={isLoading}
        columns={[
          { name: "Code" },
          { name: "Veröffentlicht?" },
          { name: "Name der Einrichtung" },
          { name: "Adresse" },
          { name: "Email" },
          { name: "Erstelldatum" },
          { name: "Ersteller" },
        ]}
        rows={instances}
        keyExtractor={(data) => data.id}
        renderRow={(data) => {
          return (
            <>
              <DataTableCell>
                <Link to={`/instances/edit/${data.id}`}>
                  {data.publicIdentifier}
                </Link>
              </DataTableCell>
              <DataTableCell>{data.isPublished ? "Ja" : "Nein"}</DataTableCell>
              <DataTableCell>{data.name}</DataTableCell>
              <DataTableCell>
                {data.street + ", " + data.zipcode + " " + data.city}
              </DataTableCell>
              <DataTableCell>{data.email}</DataTableCell>
              <DataTableCell>
                {format(data.createdAt, DATE_TIME_FORMAT)}
              </DataTableCell>
              <DataTableCell>{data.createdBy}</DataTableCell>
            </>
          );
        }}
        renderEmpty={() => <div>Empty</div>}
      />
    </DataContainer>
  );
}
