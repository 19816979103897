import React, { useCallback, useMemo } from "react";
import { MenuItem, Divider } from "@material-ui/core";
import AvatarMenuLayout from "../../../template/presentation/components/nav/AvatarMenuLayout";
import { useCurrentUser } from "features/login/presentation/hooks/useUser";

interface Props {
  handleRouteChange: (path: string) => void;
}

export function AvatarMenu({ handleRouteChange }: Props) {
  const handleLogout = useCallback(
    (close) => {
      localStorage.removeItem("accessToken");
      handleRouteChange("/login");
      close();
    },
    [handleRouteChange]
  );

  const currentUserQuery = useCurrentUser();

  const initials = useMemo(() => {
    if (currentUserQuery.data) {
      const firstname = currentUserQuery.data.firstname;
      const lastname = currentUserQuery.data.lastname;
      return `${firstname[0].toUpperCase()}${lastname[0].toUpperCase()}`;
    } else {
      return "";
    }
  }, [currentUserQuery.data]);

  const fullname = useMemo(() => {
    if (currentUserQuery.data) {
      const firstname = currentUserQuery.data.firstname;
      const lastname = currentUserQuery.data.lastname;
      return `${firstname} ${lastname}`;
    } else {
      return "";
    }
  }, [currentUserQuery.data]);

  return (
    <AvatarMenuLayout
      name={initials}
      renderMenu={(close) => [
        <MenuItem key="0">{fullname}</MenuItem>,
        <Divider key="1" />,
        <MenuItem
          key="2"
          onClick={() => {
            handleLogout(close);
          }}
        >
          Logout
        </MenuItem>,
      ]}
    />
  );
}
