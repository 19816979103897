import React from "react";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, {
  DataTableCell,
} from "template/presentation/components/table/DataTable";
import { HealthInstance } from "../../domain/entities/HealthInstance";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

export interface HealthTableProps {
  healthInstances: HealthInstance[] | null;
  isLoading: boolean;
  onReload: () => void;
}

const useStyles = makeStyles(() => ({
  checkIcon: {
    color: green[600],
  },
  errorIcon: {
    color: red[600],
  },
}));

export default function HealthTable(props: HealthTableProps) {
  const { healthInstances, isLoading, onReload } = props;
  const classes = useStyles();
  return (
    <DataContainer loading={isLoading} onReload={onReload}>
      <DataTable<HealthInstance>
        loading={isLoading}
        columns={[
          { name: "Instanz" },
          { name: "Erreichbar?" },
          { name: "Middleware Version" },
          { name: "Datenbankverbindung" },
          { name: "Task-Queue" },
        ]}
        rows={healthInstances}
        keyExtractor={(data) => data.publicIdentifier}
        renderRow={(data) => {
          return (
            <>
              <DataTableCell>{data.publicIdentifier}</DataTableCell>
              <DataTableCell>
                {data.reachable ? (
                  <div>
                    Ja <CheckIcon className={classes.checkIcon} />
                  </div>
                ) : (
                  <div>
                    Nein <ErrorIcon className={classes.errorIcon} />
                  </div>
                )}
              </DataTableCell>
              <DataTableCell>{data.version || "keine Angabe"}</DataTableCell>
              <DataTableCell>
                {data.dbReady ? (
                  <div>
                    Ok <CheckIcon className={classes.checkIcon} />
                  </div>
                ) : (
                  <div>
                    Fehlerhaft <ErrorIcon className={classes.errorIcon} />
                  </div>
                )}
              </DataTableCell>
              <DataTableCell>
                {data.queue ? (
                  <div>
                    <ul>
                      <li>Jobs</li>
                      <ul>
                        <li>Aktiv: {data.queue.jobs["active"]}</li>
                        <li>Durchgeführt: {data.queue.jobs["completed"]}</li>
                        <li>Warten: {data.queue.jobs["waiting"]}</li>
                        <li>Fehlgeschlagen: {data.queue.jobs["failed"]}</li>
                      </ul>
                      <li>Anzahl Worker: {data.queue.worker}</li>
                    </ul>
                  </div>
                ) : (
                  "nicht aktiv"
                )}
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => <div>Empty</div>}
      />
    </DataContainer>
  );
}
