import React, { ReactNode } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useLocalStorage } from "react-use";

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const [accessToken] = useLocalStorage(
    "accessToken",
    localStorage.getItem("accessToken")
  );

  return (
    <Route
      {...rest}
      render={(props): ReactNode =>
        accessToken ? (
          <Route {...props} component={Component} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
