import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  dialogContainer: {
    padding: "16px 24px",
  },
  buttonContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});

export interface ConfirmDialogProps {
  onCancel: () => void;
  onAccept: () => void;
  title: string;
  body: string;
  open: boolean;
  cancelText?: string;
  acceptText?: string;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const classes = useStyles();
  const {
    open,
    onCancel,
    onAccept,
    title,
    body,
    cancelText = "Nein",
    acceptText = "Ja",
  } = props;

  return (
    <Dialog onClose={onCancel} aria-labelledby="confirm-dialog" open={open}>
      <div className={classes.dialogContainer}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1" gutterBottom>
          {body}
        </Typography>
        <div className={classes.buttonContainer}>
          <Button onClick={onCancel} variant="contained">
            {cancelText}
          </Button>
          <Button onClick={onAccept} variant="contained" color="secondary">
            {acceptText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
