import container from "./container";
import LoginService from "features/login/domain/services/LoginService";
import UserService from "features/users/domain/services/UserService";
import LoginApi from "features/login/data/LoginApi";
import { authorizedClient, unauthorizedClient } from "core/data/clients";
import { InstanceRepo } from "features/instances/data/repositories/InstanceRepo";
import InstanceService from "features/instances/domain/services/InstanceService";
import { HealthRepo } from "features/health/data/repositories/HealthRepo";
import HealthService from "features/health/domain/services/HealthService";

export default function initDi() {
  const loginService = new LoginService(
    new LoginApi(unauthorizedClient, authorizedClient)
  );
  container.bind(LoginService).toConstantValue(loginService);

  const userService = new UserService();
  container.bind(UserService).toConstantValue(userService);

  const instanceRepo = new InstanceRepo(authorizedClient);
  const instanceService = new InstanceService(instanceRepo);
  container.bind(InstanceService).toConstantValue(instanceService);

  const healthRepo = new HealthRepo(authorizedClient);
  const healthService = new HealthService(healthRepo, instanceService);
  container.bind(HealthService).toConstantValue(healthService);
}
