import useService from "core/di/useService";
import InstanceService from "features/instances/domain/services/InstanceService";
import { useQuery } from "react-query";

export default function useInstance(id: string) {
  const instanceService = useService(InstanceService);

  const { data, isLoading, refetch, isFetching, isError } = useQuery(
    ["/private/instances", id],
    () => instanceService.getById(id)
  );

  return {
    instance: data,
    isLoading,
    refetch,
    isFetching,
    isError,
  };
}
