import React from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import HealthService from "../../domain/services/HealthService";
import HealthTable from "../components/HealthTable";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";

export interface HealthRouteProps extends RouteComponentProps {}

const refetchIntervalInMinutes = 1;

export default function HealthRoute(props: HealthRouteProps) {
  const healthInstanceService = useService(HealthService);
  const { data, isLoading, refetch, isFetching } = useQuery(
    "allHealthInstances",
    () => healthInstanceService.getAll(),
    {
      refetchInterval: refetchIntervalInMinutes * 60000,
    }
  );

  return (
    <UIRoute {...props} title="Health">
      <HealthTable
        healthInstances={data || null}
        isLoading={isLoading || isFetching}
        onReload={refetch}
      />
    </UIRoute>
  );
}
