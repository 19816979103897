import User from "../entities/UserEntity";

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

export default class UserService {
  public async getAllUsers(): Promise<User[]> {
    console.log("UserService.getAllUsers");
    await sleep(2500);

    return Promise.resolve([
      {
        lastname: "Christen",
        firstname: "Oliver",
        email: "christen@app-logik.de",
      },
      {
        lastname: "Borchert",
        firstname: "Christopher",
        email: "dev@app-logik.de",
      },
    ]);
  }
}
