import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import theme from "template/utils/theme/theme";
import Drawer from "../nav/Drawer";

const styles = createStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
});

export interface BaseScreenLayoutProps extends WithStyles<typeof styles> {
  title: string;
  navigationCategories: any[];
  children: React.ReactChild | React.ReactChildren;
  path: string;
  handleRouteChange: (path: string) => void;
}

function BaseScreenLayout(props: BaseScreenLayoutProps) {
  const {
    classes,
    title,
    navigationCategories,
    path,
    handleRouteChange,
    children,
  } = props;

  return (
    <div className={classes.root}>
      <Drawer
        title={title}
        categories={navigationCategories}
        path={path}
        handleRouteChange={handleRouteChange}
      />
      <div className={classes.app}>{children}</div>
    </div>
  );
}

export default withStyles(styles)(BaseScreenLayout);
