import { useRef } from "react";
import container from "./container";
import { interfaces } from "inversify";

export default function useService<T>(
  serviceIdentifier: interfaces.ServiceIdentifier<T>
): T {
  const ref = useRef<T>(container.get(serviceIdentifier));
  return ref.current;
}
