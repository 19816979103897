import { HealthRepo } from "features/health/data/repositories/HealthRepo";
import InstanceService from "features/instances/domain/services/InstanceService";
import { HealthInstance as HealthInstanceEntity } from "../entities/HealthInstance";

export default class HealthService {
  constructor(
    private healthRepo: HealthRepo,
    private instanceService: InstanceService
  ) {}

  public async getAll(): Promise<HealthInstanceEntity[]> {
    const instances = await this.instanceService.getAll();

    return Promise.all(
      instances.map((instance) =>
        this.healthRepo.getHealthForInstance(instance)
      )
    );
  }
}
