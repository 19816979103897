import React from "react";
import { makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import Copyright from "../nav/Copyright";
import Header from "../nav/Header";

const useStyles = makeStyles((theme) => ({
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
}));

export interface BaseRouteProps extends RouteComponentProps {
  title: string;
  renderSubHeader?: () => React.ReactNode;
  renderIcons?: () => React.ReactNode[];
  children: React.ReactChild | React.ReactChildren;
  testID?: string;
}

function BaseRoute({
  title,
  renderSubHeader,
  renderIcons,
  children,
  testID,
}: BaseRouteProps) {
  const classes = useStyles();

  return (
    <div data-testid={testID} className={classes.app}>
      <Header
        title={title}
        renderSecondaryHeader={renderSubHeader}
        renderIcons={renderIcons}
      />
      <main className={classes.main}>{children}</main>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
    </div>
  );
}

export default BaseRoute;
