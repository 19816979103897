import { AxiosError, AxiosInstance } from "axios";
import { Instance } from "features/instances/domain/entities/Instance";
import { HealthInstance } from "../models/HealthInstance";

export class HealthRepo {
  constructor(private authorizedAxiosInstance: AxiosInstance) {}

  public async getHealthForInstance(
    instance: Instance
  ): Promise<HealthInstance> {
    const { data } = await this.authorizedAxiosInstance.get(
      "private/instances/health/" + instance.id
    );

    return data;
  }

  public async getAll(): Promise<HealthInstance[]> {
    const { data } = await this.authorizedAxiosInstance.get<HealthInstance[]>(
      "/private/instances/health"
    );

    return data;
  }
}
