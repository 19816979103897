import { useCallback } from "react";
import { UIStore } from "../stores/UIStore";

export function useDrawer() {
  const drawerIsOpen = UIStore.useState((s) => s.drawerIsOpen);

  const handleDrawerToggle = useCallback(() => {
    UIStore.update((s) => {
      s.drawerIsOpen = !s.drawerIsOpen;
    });
  }, []);

  return { drawerIsOpen, handleDrawerToggle };
}
