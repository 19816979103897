import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import UsersRoute from "features/users/presentation/routes/UsersRoute";
import DashboardRoute from "features/dashboard/presentation/routes/DashboardRoute";
import BaseScreenLayout from "../../../template/presentation/components/layout/BaseScreenLayout";
import { APP_NAME_DRAWER } from "core/config";
import { useNavigationCategories } from "../hooks/useNavigationCategories";
import InstancesRoute from "features/instances/presentation/routes/InstancesRoute";
import NewInstanceRoute from "features/instances/presentation/routes/NewInstanceRoute";
import EditInstanceRoute from "features/instances/presentation/routes/EditInstanceRoute";
import HealthRoute from "features/health/presentation/routes/HealthRoute";

export interface BaseScreenProps extends RouteComponentProps {}

function BaseScreen(props: BaseScreenProps) {
  const navigationCategories = useNavigationCategories();

  return (
    <BaseScreenLayout
      title={APP_NAME_DRAWER}
      navigationCategories={navigationCategories}
      path={props.location.pathname}
      handleRouteChange={props.history.push}
    >
      <Switch>
        <Route exact path="/">
          <Redirect to="/instances" />
        </Route>
        <Route exact path="/instances" component={InstancesRoute} />
        <Route path="/instances/new" component={NewInstanceRoute} />
        <Route path="/instances/edit/:id" component={EditInstanceRoute} />
        <Route path="/health" component={HealthRoute} />
      </Switch>
    </BaseScreenLayout>
  );
}

export default BaseScreen;
