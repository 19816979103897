import React from "react";
import { createStyles, WithStyles, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import theme from "template/utils/theme/theme";
import Navigation from "./Navigation";
import { useDrawer } from "../hooks/useDrawer";

const drawerWidth = 256;

const styles = createStyles({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
});

export interface DrawerProps extends WithStyles<typeof styles> {
  title: string;
  categories: any[];
  path: string;
  handleRouteChange: (path: string) => void;
}

function Drawer(props: DrawerProps) {
  const { classes, ...other } = props;

  const { drawerIsOpen, handleDrawerToggle } = useDrawer();

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="js">
        <Navigation
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={drawerIsOpen}
          onClose={handleDrawerToggle}
          {...other}
        />
      </Hidden>
      <Hidden xsDown implementation="css">
        <Navigation PaperProps={{ style: { width: drawerWidth } }} {...other} />
      </Hidden>
    </nav>
  );
}

export default withStyles(styles)(Drawer);
