import React from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import InstanceService from "../../domain/services/InstanceService";
import InstancesTable from "../components/InstancesTable";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";

export interface InstancesRouteProps extends RouteComponentProps {}

export default function InstancesRoute(props: InstancesRouteProps) {
  const instanceService = useService(InstanceService);
  const { data, isLoading, refetch, isFetching } = useQuery(
    "allInstances",
    () => instanceService.getAll()
  );

  return (
    <UIRoute {...props} title="Instanzen">
      <InstancesTable
        instances={data || null}
        isLoading={isLoading || isFetching}
        onReload={refetch}
        onAdd={() => props.history.push("/instances/new")}
      />
    </UIRoute>
  );
}
