import { useCurrentUser } from "features/login/presentation/hooks/useUser";
import adminMenu from "../menues/adminMenu";

export function useNavigationCategories() {
  const aboutMeQuery = useCurrentUser();
  const roles = aboutMeQuery.data?.roles || [];
  if (roles.includes("admin")) {
    return adminMenu;
  } else {
    return [];
  }
}
