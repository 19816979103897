import LoginApi from "../../data/LoginApi";
import LoginToken from "../entities/LoginToken";

export default class LoginService {
  constructor(private loginApi: LoginApi) {}

  public async login(username: string, password: string): Promise<LoginToken> {
    try {
      const token = await this.loginApi.login(username, password);
      return token;
    } catch (e) {
      // TODO: process error
      throw e;
    }
  }

  public aboutMe() {
    return this.loginApi.aboutMe();
  }
}
