import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { Form } from "formik";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
}));

const FormContainer: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Form>{children}</Form>
    </Paper>
  );
};
export default FormContainer;
