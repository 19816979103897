import React, { useMemo, useState } from "react";
import {
  Instance,
  InstanceFeatureToggle,
} from "features/instances/domain/entities/Instance";
import { Field, Formik } from "formik";
import {
  CheckboxWithLabel,
  SimpleFileUpload,
  TextField,
} from "formik-material-ui";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import * as Yup from "yup";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
// import useFeatures from "features/instances/data/hooks/useFeatures";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 963,
  },
  field: {
    marginTop: theme.spacing(1),
  },
  groupLabel: {
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    padding: 16,
  },
  cancelSubmitContainer: {
    display: "flex",
    flex: 3,
    justifyContent: "flex-end",
  },
  deleteButton: {
    width: "20%",
    flex: 1,
  },
  cancelButton: {
    width: "30%",
    marginRight: 5,
  },
  submitButton: {
    width: "30%",
  },
  uploadImageContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 200,
    "& > img": {
      maxWidth: 200,
      marginTop: 10,
    },
    "& > button": {
      marginTop: 5,
    },
  },
}));

export interface InstanceFormProps {
  instanceToEdit?: Instance;
  onSubmit: (newInstance: InstanceFormInputs) => Promise<void>;
  onCancel: () => void;
  onDelete?: () => void;
}

export interface InstanceFormInputs {
  publicIdentifier: string;
  name: string;
  street: string;
  zipcode: string;
  city: string;
  phone?: string;
  email?: string;
  middlewareUrl: string;
  companyLogo?: Blob;
  companyIcon?: Blob;
  features: InstanceFeatureToggle[];
  termsAndConditions: string;
  imprint: string;
  dataPrivacy: string;
  isPublished: boolean;
}

// TODO create separate form components for creating and editing an instance (SRP)
export default function InstanceForm(props: InstanceFormProps) {
  const classes = useStyles();
  const { onSubmit, onCancel, instanceToEdit, onDelete } = props;

  const [showCompanyIconIfEditMode, setShowCompanyIconIfEditMode] = useState<
    boolean
  >(true);
  const [showCompanyLogoIfEditMode, setShowCompanyLogoIfEditMode] = useState<
    boolean
  >(true);

  const features = [
    {
      activated: false,
      name: "premium",
    },
  ]; // useFeatures();

  const instanceSchema = useMemo(() => {
    const schema: any = {
      publicIdentifier: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      name: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      street: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      zipcode: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      city: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      email: Yup.string()
        .notRequired()
        .email("Bitte eine valide Email angeben"),
      phone: Yup.string().notRequired(),
      termsAndConditions: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      imprint: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      dataPrivacy: Yup.string()
        .min(1, "Mindestens ein Zeichen benötigt")
        .required("Bitte ausfüllen"),
      middlewareUrl: Yup.string()
        .url("Bitte eine valide URL angeben")
        .required("Bitte ausfüllen"),
    };

    if (!instanceToEdit || (instanceToEdit && !showCompanyIconIfEditMode)) {
      schema.companyIcon = Yup.mixed().required("Bitte eine Datei hochladen");
    }

    if (!instanceToEdit || (instanceToEdit && !showCompanyLogoIfEditMode)) {
      schema.companyLogo = Yup.mixed().required("Bitte eine Datei hochladen");
    }

    return Yup.object().shape(schema);
  }, [instanceToEdit, showCompanyIconIfEditMode, showCompanyLogoIfEditMode]);

  return (
    <Formik<InstanceFormInputs>
      initialValues={{
        publicIdentifier: instanceToEdit ? instanceToEdit.publicIdentifier : "",
        name: instanceToEdit ? instanceToEdit.name : "",
        street: instanceToEdit ? instanceToEdit.street : "",
        zipcode: instanceToEdit ? instanceToEdit.zipcode : "",
        email: instanceToEdit ? instanceToEdit.email : "",
        phone: instanceToEdit ? instanceToEdit.phone : "",
        city: instanceToEdit ? instanceToEdit.city : "",
        features: instanceToEdit ? instanceToEdit.features : features,
        termsAndConditions: instanceToEdit
          ? instanceToEdit.termsAndConditions
          : "",
        imprint: instanceToEdit ? instanceToEdit.imprint : "",
        dataPrivacy: instanceToEdit ? instanceToEdit.dataPrivacy : "",
        isPublished: instanceToEdit ? instanceToEdit.isPublished : false,
        middlewareUrl: instanceToEdit ? instanceToEdit.middlewareUrl : "",
        companyIcon: undefined,
        companyLogo: undefined,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={instanceSchema}
    >
      {({ values, submitForm, isSubmitting, setFieldValue }) => (
        <FormContainer>
          <FormSection label="Identifikation der Instanz">
            <Field
              component={TextField}
              name="publicIdentifier"
              type="text"
              label="*Schlüssel"
              variant="outlined"
              className={classes.field}
            />
          </FormSection>

          <FormSection label="Anschrift">
            <Field
              component={TextField}
              name="name"
              type="text"
              label="*Name der Einrichtung"
              variant="outlined"
              className={classes.field}
            />
            <Field
              component={TextField}
              name="street"
              type="text"
              label="*Straße und Hausnummer"
              variant="outlined"
              className={classes.field}
            />
            <Field
              component={TextField}
              name="zipcode"
              type="text"
              label="*Postleitzahl"
              variant="outlined"
              className={classes.field}
            />
            <Field
              component={TextField}
              name="city"
              type="text"
              label="*Stadt"
              variant="outlined"
              className={classes.field}
            />
          </FormSection>
          <FormSection label="Kontakt">
            <Field
              component={TextField}
              name="email"
              type="text"
              label="E-Mail"
              variant="outlined"
              className={classes.field}
            />
            <Field
              component={TextField}
              name="phone"
              type="text"
              label="Telefon"
              variant="outlined"
              className={classes.field}
            />
          </FormSection>

          <FormSection label="Aktivierte Features">
            <FormGroup row>
              {values.features.map((feature, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={feature.name}
                      name={feature.name}
                      checked={feature.activated}
                      onClick={() => {
                        const newFeatures = [...values.features];
                        const indexOfFeatureToUpdate = newFeatures.findIndex(
                          (newFeature) => newFeature.name === feature.name
                        );
                        newFeatures[
                          indexOfFeatureToUpdate
                        ].activated = !feature.activated;

                        setFieldValue("features", newFeatures);
                      }}
                    />
                  }
                  label={feature.name}
                />
              ))}
            </FormGroup>
          </FormSection>

          <FormSection label="Rechtliches">
            <Field
              component={TextField}
              multiline
              rows={8}
              placeholder="Hier AGBs in Markdown eingeben"
              name="termsAndConditions"
              type="textarea"
              label="*AGBs"
              variant="outlined"
              className={classes.field}
            />
            <Field
              component={TextField}
              multiline
              rows={8}
              placeholder="Hier Impressum in Markdown eingeben"
              name="imprint"
              type="textarea"
              label="*Impressum"
              variant="outlined"
              className={classes.field}
            />
            <Field
              component={TextField}
              multiline
              rows={8}
              placeholder="Hier Datenschutzbestimmungen in Markdown eingeben"
              name="dataPrivacy"
              type="textarea"
              label="*Datenschutzbestimmungen"
              variant="outlined"
              className={classes.field}
            />
          </FormSection>

          <FormSection label="URL zur Middleware-Instanz">
            <Field
              component={TextField}
              name="middlewareUrl"
              type="text"
              label="*Middleware URL"
              variant="outlined"
              className={classes.field}
            />
          </FormSection>
          <FormSection label="Sichtbarkeit für alle Apps">
            <Field
              component={CheckboxWithLabel}
              name="isPublished"
              type="checkbox"
              Label={{ label: "Veröffentlichen" }}
            />
          </FormSection>

          <FormSection label="Instanzspezifische Bilder für alle Apps">
            {instanceToEdit && showCompanyLogoIfEditMode ? (
              <div className={classes.uploadImageContainer}>
                <FormLabel className={classes.groupLabel}>Logo</FormLabel>
                <img src={instanceToEdit.companyLogoUrl} />
                <Button
                  color="secondary"
                  onClick={() => setShowCompanyLogoIfEditMode(false)}
                >
                  Anderes Bild wählen
                </Button>
              </div>
            ) : (
              <Field
                component={SimpleFileUpload}
                name="companyLogo"
                label="*Logo"
              />
            )}
            {instanceToEdit && showCompanyIconIfEditMode ? (
              <div className={classes.uploadImageContainer}>
                <FormLabel className={classes.groupLabel}>Icon</FormLabel>
                <img src={instanceToEdit.companyIconUrl} />
                <Button
                  color="secondary"
                  onClick={() => setShowCompanyIconIfEditMode(false)}
                >
                  Anderes Bild wählen
                </Button>
              </div>
            ) : (
              <Field
                component={SimpleFileUpload}
                name="companyIcon"
                label="*Icon"
              />
            )}
          </FormSection>

          <div className={classes.buttonContainer}>
            {instanceToEdit && (
              <Button
                className={classes.deleteButton}
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                onClick={onDelete}
              >
                Instanz löschen
              </Button>
            )}
            <div className={classes.cancelSubmitContainer}>
              <Button
                className={classes.cancelButton}
                variant="contained"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Abbrechen
              </Button>
              <Button
                className={classes.submitButton}
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {instanceToEdit ? "Änderungen speichern" : "Instanz anlegen"}
              </Button>
            </div>
          </div>
        </FormContainer>
      )}
    </Formik>
  );
}
