import React, { useCallback, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import useService from "../../../../core/di/useService";
import LoginService from "../../domain/services/LoginService";
import { useHistory } from "react-router-dom";
import LoginForm, { LoginFormValues } from "../components/LoginForm";
import { APP_NAME_LOGIN } from "core/config";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginScreen() {
  const classes = useStyles();

  const loginService = useService(LoginService);
  const history = useHistory();

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    (values: LoginFormValues) => {
      return loginService
        .login(values.username, values.password)
        .then((token) => {
          localStorage.setItem("accessToken", token.accessToken);
          localStorage.setItem("refreshToken", token.refreshToken);

          history.replace("/");
        })
        .catch((e) => {
          setError(e.message);
          throw e;
        });
    },
    [history, loginService]
  );

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {APP_NAME_LOGIN}
        </Typography>
        <LoginForm handleSubmit={handleSubmit} error={error} />
      </div>
    </Container>
  );
}
