import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

export const DataTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#f5f5f5",
      color: "rgba(0,0,0,0.54)",
      fontWeight: 600,
      fontSize: 12,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "3px 15px",
    },
    body: {
      fontSize: 13,
      color: "rgba(0,0,0,0.54)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
  })
)(TableCell);

export const DataTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface DataTableColumn {
  name: string;
}

interface DataTableProps<T> {
  columns: DataTableColumn[];
  rows: T[] | null | undefined;
  loading?: boolean;
  renderRow: (data: T, index: number) => React.ReactNode;
  keyExtractor: (data: T, index: number) => string;
  renderEmpty?: () => React.ReactNode;
}

function DataTable<T>(
  props: DataTableProps<T>
): React.ReactElement<DataTableProps<T>> {
  const {
    columns,
    rows,
    loading,
    renderRow,
    renderEmpty,
    keyExtractor,
  } = props;
  const classes = useStyles();

  const hasEntries = rows && rows.length > 0;

  return (
    <div style={{ position: "relative" }}>
      {hasEntries && loading ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            paddingTop: 24,
            justifyContent: "center",
            backgroundColor: "#FFFFFF88",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((x) => (
              <DataTableCell key={x.name}>{x.name}</DataTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => (
              <DataTableRow key={keyExtractor(row, index)}>
                {renderRow(row, index)}
              </DataTableRow>
            ))}
        </TableBody>
      </Table>
      {!hasEntries && loading ? (
        <div
          style={{
            display: "flex",
            padding: 24,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      {!hasEntries && !loading && renderEmpty ? renderEmpty() : null}
    </div>
  );
}

export default DataTable;
