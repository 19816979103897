import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {},
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "0px 0px",
    },
    toolbar: {
      paddingRight: 8,
    },
  });

export interface DataContainerProps extends WithStyles<typeof styles> {
  loading?: boolean;
  searchPlaceholder?: string;
  addButton?: string;
  onAdd?: () => void;
  onReload?: () => void;
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const {
    classes,
    loading,
    searchPlaceholder,
    addButton,
    onAdd,
    onReload,
    children,
  } = props;

  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} alignItems="center" justify="flex-end">
            {searchPlaceholder && (
              <>
                <Grid item>
                  <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    value={searchValue}
                    onChange={(x) => {
                      setSearchValue(x.target.value as string);
                    }}
                    placeholder={searchPlaceholder}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.searchInput,
                    }}
                    disabled={loading === true}
                  />
                </Grid>
              </>
            )}
            <Grid item>
              {addButton && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={onAdd === undefined || loading === true}
                  className={classes.addUser}
                  onClick={onAdd}
                >
                  {addButton}
                </Button>
              )}
              {onReload && (
                <Tooltip title="Reload">
                  <span>
                    <IconButton onClick={onReload} disabled={loading === true}>
                      <RefreshIcon className={classes.block} color="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {/*
        <Typography color="textSecondary" align="center">
          No users for this project yet
        </Typography>
        */}
        {children}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DataContainer);
