import React from "react";
import { HashRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "template/utils/theme/theme";
import { MainRoutes } from "core/presentation/components/MainRoutes";

function Root() {
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainRoutes />
      </ThemeProvider>
    </HashRouter>
  );
}

export default Root;
