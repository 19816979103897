import { parseISO } from "date-fns";
import { Instance as InstanceModel } from "features/instances/data/models/Instance";
import { InstanceRepo } from "features/instances/data/repositories/InstanceRepo";
import { InstanceFormInputs } from "features/instances/presentation/components/InstanceForm";
import {
  Instance as InstanceEntity,
  InstanceFeatureToggle,
} from "../entities/Instance";
import { NewInstance } from "../entities/NewInstance";

export default class InstanceService {
  constructor(private instanceRepo: InstanceRepo) {}

  public async getAllInstanceIds() {
    const instances = await this.getAll();

    return instances.map((instance) => instance.id);
  }

  public async getAll(): Promise<InstanceEntity[]> {
    const instances = await this.instanceRepo.getAll();

    return instances.map((instance) => this.modelToEntity(instance));
  }

  public async getById(id: string): Promise<InstanceEntity> {
    const instance = await this.instanceRepo.getById(id);

    return this.modelToEntity(instance);
  }

  private modelToEntity(instance: InstanceModel): InstanceEntity {
    return {
      ...instance,
      createdAt: parseISO(instance.createdAt),
      updatedAt: parseISO(instance.updatedAt),
    };
  }

  public async create(instanceFormInputs: InstanceFormInputs) {
    const newInstance = this.cleanFormInputs(instanceFormInputs);

    return this.instanceRepo.create(newInstance);
  }

  public async update(id: string, instanceFormInputs: InstanceFormInputs) {
    const updatedInstance = this.cleanFormInputs(instanceFormInputs);

    return this.instanceRepo.update(id, updatedInstance);
  }

  private cleanFormInputs(instanceFormInputs: InstanceFormInputs): NewInstance {
    const cleanedInstance: NewInstance = {
      ...instanceFormInputs,
    };

    if (cleanedInstance.email === "") {
      delete cleanedInstance.email;
    }
    if (cleanedInstance.phone === "") {
      delete cleanedInstance.phone;
    }
    return cleanedInstance;
  }

  public async delete(id: string) {
    return this.instanceRepo.delete(id);
  }

  public async getAvailableFeatures(): Promise<InstanceFeatureToggle[]> {
    return this.instanceRepo.getAvailableFeatures();
  }
}
