import React, { useCallback, useState } from "react";
import useService from "core/di/useService";
import InstanceService from "../../domain/services/InstanceService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import InstanceForm from "../components/InstanceForm";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { InvalidInstanceFormInput } from "features/instances/data/repositories/InstanceRepo";

export interface NewInstanceRouteProps extends RouteComponentProps {}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NewInstanceRoute(props: NewInstanceRouteProps) {
  const instanceService = useService(InstanceService);
  const [error, setError] = useState<string>("");
  const handleCloseError = useCallback(() => {
    setError("");
  }, []);

  return (
    <UIRoute {...props} title="Neue Instanz">
      <>
        <InstanceForm
          onSubmit={async (newInstance) => {
            try {
              await instanceService.create(newInstance);
              props.history.push("/instances");
            } catch (err) {
              if (err instanceof InvalidInstanceFormInput) {
                setError(err.invalidInput.join("\n"));
              } else {
                setError(err.message);
              }
            }
          }}
          onCancel={() => props.history.push("/instances")}
        ></InstanceForm>
        <Snackbar
          open={error !== ""}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </>
    </UIRoute>
  );
}
