import React, { useCallback, useState } from "react";
import useService from "core/di/useService";
import InstanceService from "../../domain/services/InstanceService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import InstanceForm from "../components/InstanceForm";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { InvalidInstanceFormInput } from "features/instances/data/repositories/InstanceRepo";
import { Backdrop, makeStyles } from "@material-ui/core";
import ConfirmDialog from "core/presentation/components/ConfirmDialog";
import useInstance from "core/presentation/hooks/useInstance";
import { useQueryCache } from "react-query";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface RouteParams {
  id: string;
}

export interface EditInstanceRouteProps
  extends RouteComponentProps<RouteParams> {}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function EditInstanceRoute(props: EditInstanceRouteProps) {
  const { history, match } = props;
  const instanceId = match.params.id;

  const instanceService = useService(InstanceService);
  const [error, setError] = useState<string>("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const handleCloseError = useCallback(() => {
    setError("");
  }, []);
  const classes = useStyles();

  const { instance } = useInstance(instanceId);
  const queryCache = useQueryCache();

  return (
    <UIRoute {...props} title="Instanz editieren">
      <>
        {!instance ? (
          <Backdrop className={classes.backdrop} open={true}></Backdrop>
        ) : (
          <>
            <InstanceForm
              instanceToEdit={instance}
              onSubmit={async (updatedInstance) => {
                try {
                  await instanceService.update(instanceId, updatedInstance);
                  queryCache.invalidateQueries([
                    "/private/instances",
                    instanceId,
                  ]);
                  history.push("/instances");
                } catch (err) {
                  if (err instanceof InvalidInstanceFormInput) {
                    setError(err.invalidInput.join("\n"));
                  } else {
                    setError(err.message);
                  }
                }
              }}
              onCancel={() => props.history.push("/instances")}
              onDelete={async () => {
                try {
                  setDeleteDialogOpen(true);
                } catch (err) {
                  setError(err.message);
                }
              }}
            ></InstanceForm>
            <ConfirmDialog
              open={deleteDialogOpen}
              title="Instanz löschen"
              body={`Wollen Sie die Instanz "${instance.publicIdentifier}" wirklich löschen?`}
              onAccept={async () => {
                try {
                  await instanceService.delete(instanceId);
                  setDeleteDialogOpen(false);
                  history.push("/instances");
                } catch (err) {
                  setDeleteDialogOpen(true);
                  setError(err.message);
                }
              }}
              onCancel={() => setDeleteDialogOpen(false)}
            />
          </>
        )}
        <Snackbar
          open={error !== ""}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </>
    </UIRoute>
  );
}
