import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface LoginFormValues {
  username: string;
  password: string;
}

interface LoginFormProps {
  handleSubmit: (values: LoginFormValues) => Promise<void>;
  error: string | null;
}

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Keine gültige E-Mail Adresse")
    .required("Benötigt"),
  password: Yup.string()
    .min(8, "Mindestens 8 Zeichen erforderlich")
    .required("Benötigt"),
});

export default function LoginForm({ handleSubmit, error }: LoginFormProps) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={loginSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <div className={classes.form}>
            <Form id="login-form">
              <Field
                component={TextField}
                name="username"
                type="email"
                label="E-Mail"
                fullWidth
                variant="outlined"
                margin="normal"
                autoComplete="email"
                autoFocus
              />
              <Field
                component={TextField}
                name="password"
                label="Passwort"
                type="password"
                fullWidth
                variant="outlined"
                margin="normal"
                autoComplete="current-password"
              />
              {error && <Alert severity="error">{error}</Alert>}
              <Button
                fullWidth
                data-testid="signin-button"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Passwort vergessen?
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </div>
        </>
      )}
    </Formik>
  );
}
