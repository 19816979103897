import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "template/presentation/components/routes/PrivateRoute";
import LoginScreen from "features/login/presentation/screens/LoginScreen";
import BaseScreen from "core/presentation/components/BaseScreen";

interface ContentRoutesProps {}

export const MainRoutes: React.FC<ContentRoutesProps> = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginScreen} />
      <PrivateRoute path="/" component={BaseScreen} />
    </Switch>
  );
};
