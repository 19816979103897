import React from "react";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import LocalHospital from "@material-ui/icons/LocalHospital";

export default [
  {
    id: "Allgemein",
    children: [
      { id: "Instanzen", icon: <DnsRoundedIcon />, path: "/instances" },
      { id: "Health", icon: <LocalHospital />, path: "/health" },
    ],
  },
];
